import React, { useState, useEffect } from 'react';
import { withRouter, useParams, Prompt } from 'react-router';
const OptionDropDown = ({options,borderState}) => {

    const [dropdownOpen,setDropDownOpen]=useState(false);

    const camelCaseParser=(text)=>{
      return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
    }


    console.log(options)
    const checks=options&&options.length&&options.map((option,i) => {
        return <label key={i} className="px-4 flex  cursor-pointer gap-x-2 py-1 text-xs capitalize text-gray-700 leading-tight font-semibold font-['Inter'] ">
        <input type="checkbox" checked={option.value} onChange={(e)=>{  option.onchange([option.name,e.target.checked,options])}} className=' cursor-pointer p-0.5 bg-gray-50 rounded border border-blue-600 justify-center items-center flex'/>
    <div>{camelCaseParser( option.name)}</div>
          
        </label>
    })
  

  return (
    <React.Fragment>
<div  className="h-[46px] flex-col justify-start items-start inline-flex">
<button onClick={()=>{setDropDownOpen(!dropdownOpen)}} className={"text-slate-700 grow shrink w-11 mt-1    bg-white rounded-lg shadow border  justify-center items-center  inline-flex "+(borderState)}>
<img className=" h-5 w-5   " src="/icons/options.svg" />

  </button>
  {dropdownOpen&&<div x-show="dropdownOpen" onClick={()=>{setDropDownOpen(!dropdownOpen)}} className="fixed inset-0 h-full w-full z-10"></div>}

{dropdownOpen&&<div  className="absolute columns-1  mt-[46px] py-2 w-64 bg-white rounded-md shadow-xl z-20">
  
  {checks!==null&&checks!==undefined&&checks.length>0&&checks}
  </div>}

</div>
    </React.Fragment>
  )
}

export default OptionDropDown