import React, { useState, useEffect, act } from 'react';
import { withRouter, useParams, Prompt } from 'react-router';
import { ViewState } from './EditCoachingPlanHead.tsx'
import FilterDropDown from './FilterDropDown.tsx';
import CustomDropDown from '../CustomDropDown.jsx';
import OptionDropDown from './OptionDropDown.tsx';
interface ActivityVar{
    state:ViewState;
    activities: any;
    setActivityMethod:any;
    week: any;
    index: any;
    defaultAssignment: any;
    completedRoutineData:any
    isCalibrationActivity:Boolean;
    isRoutineActivity:Boolean;
    defaultDuration:any;
    setDurationMethod:any;
    setOptionMethod:any
    currentAssignments:any;
    originalAssignment:any
    originalDuration:any
    refreshMethod:any
    refreshCheck:any
}


enum saveState{
    assigned,
    unsaved,
    duplicate
  }



const ActivityAssignmentPanel = ({state,activities,setActivityMethod,setDurationMethod,week,index,defaultAssignment,completedRoutineData, isCalibrationActivity,isRoutineActivity, defaultDuration, currentAssignments, originalAssignment, originalDuration,refreshMethod, refreshCheck, setOptionMethod}:ActivityVar) => {
    const [selectedValue,setSelectedValue] = useState(undefined);
    const [movementFilter,setMovementFilter] = useState(false);
    const [meditationFilter,setMeditationFilter] = useState(false);
    const [coachingFilter,setcoachingFilter] = useState(false);
    const [doneFilter,setDoneFilter] = useState(false);
    const [actOptions,setActOptions] = useState<any[]|null>(null);
    const [actOptionsFiltered,setActOptionsFiltered] = useState<any[]|null>(null);
    const [durations,setDurations] = useState<any[]|null>(null);
    const [init,setInit] = useState(false);
    const [dur,setDur] = useState(defaultDuration/60);
    const [actSettings,setActSettings] = useState<any[]|null>(null);

    const [activityState,setActivityState] = useState([saveState.assigned]);
    const [durState,setDurState] = useState([saveState.assigned]);
    const [settingState,setSettingsState] = useState([saveState.assigned]);

    const [optionChange,setOptionChange]=useState(null);

   console.log(activities)

    const ChangeActivity=(activity)=>{
        if(activity===undefined)
        {
            activity=selectedValue
        }
       // setInit(!init);
        refreshMethod();
        let prevActivity= selectedValue;
        setSelectedValue(activity);
        
        setActivityMethod(activity,week,index)
        
        if((originalAssignment===null&&activity===null)||(originalAssignment!==null&&activity===originalAssignment.Id)||(activity===prevActivity))
            {
            setActivityState([saveState.assigned])
            if(prevActivity!==activity&&activity!==null){
                if(actSettings===null&&activity!==null&&activities[activity].hasOptions)
                {
                    let settings=[]
                    activities[originalAssignment.Id].OptionNames.forEach(val=>{
                        settings.push({name: val, value:(originalAssignment.Settings!==undefined&&originalAssignment.Settings[val]!==undefined?originalAssignment.Settings[val]:false), onchange: setOptionChange })
                        setOptionMethod(val,(originalAssignment.Settings!==undefined&&originalAssignment.Settings[val]!==undefined?originalAssignment.Settings[val]:false),week,index)

                    })
                
                    setActSettings(settings)
                }
            
            }
        }
        else{

            setActivityState([saveState.unsaved])
           
            if(activity!==null&&activities[activity].hasOptions){
                let settings=[]
                activities[activity].OptionNames.forEach(val=>{
                    settings.push({name: val, value:false, onchange: setOptionChange})
                })
             
                setActSettings(settings)
            }
            else
            {

                setActSettings(null);
            }
            setSettingsState([saveState.assigned]);

        }
        if(activities[activity]!==undefined&&activity!==selectedValue){
            setDurations(null);
        if(prevActivity!==activity && (originalAssignment!==null?activity===originalAssignment.Id:false))
        {
            setDur(originalDuration/60);
            setDurationMethod(originalDuration,activity,week,index)

        }
        else{
        setDur(activities[activity].duration/60);
        setDurationMethod(activities[activity].duration,activity,week,index)

        }
        setDurState([saveState.assigned])
        }

    }

    const ChangeActivityDuration=(duration)=>{
        if(originalAssignment!==null&& selectedValue===originalAssignment.Id){
           if(duration!==(originalDuration/60))
           {
          
            setDurState([saveState.unsaved])
           }
           else{
            setDurState([saveState.assigned])

           }
        }
        else{
           
            setDurState([saveState.unsaved])

        }
        setDur(duration);
        setDurationMethod(duration*60,selectedValue,week,index)
    }


  useEffect(() => {
    if(actOptions!==null)
    {
        var temp: any[]=actOptions;

        if(doneFilter)
        {
            temp=temp.filter((e) => e.completions ===0)
        }

        if(coachingFilter||meditationFilter||movementFilter)
        {
            temp=temp.filter((e) => {
                if(e.actType===undefined)
                {
                    return true;
                }
                if(coachingFilter && e.actType==="Coaching")
                {
                    return true;
                }

                if(movementFilter && e.actType==="Movement")
                {
                    return true;
                }

                if(meditationFilter && e.actType==="Meditation")
                {
                        return true;
                }

                return false;
            })

        }
        if(isRoutineActivity && temp.length===0)
        {
            actOptions.forEach(o=>{
                if(o.name===activities[ selectedValue].name)
                {
                    temp=[o];
                }
            })
        }
        setActOptionsFiltered(temp);

    }


    
}, [coachingFilter,doneFilter,meditationFilter,movementFilter,actOptions]);

useEffect(() => {
    if(actOptions===null &&activities!==null&&activities!==undefined)
    {
        var temp: any[]=[];
        var index=0;

        if(!isRoutineActivity)
        {
            temp.push(
                {
                    name: "No Activity",
                    id: index,
                    value: null,
                    completions: 1,
                    img:"data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA="


                }
            )
            index++;
        }
        for (const key in activities) {
            if(isRoutineActivity&&activities[key].type!=="Movement"){
                continue;
            }
            if(isCalibrationActivity&&(key!=="CalibrationUpperExtremity"&&key!=="FlowerGarden"))
                {
                    continue;
                }
            if(key==="AugmentationLumbarSpinePro"){continue;}
            if(key==="DogFetchPro"){continue;}
            temp.push(
                {
                    name: activities[key].name,
                    id: index,
                    value: key,
                    actType: activities[key].type,
                    completions: activities[key].completions.personal+  activities[key].completions.bonus,
                    img: "/icons/badges/CORE_"+activities[key].type+"ActivityIcon_T.png",
                    badge:{value:(activities[key].completions.personal+activities[key].completions.bonus),color:activities[key].assigned===true? (activities[key].completions.personal+activities[key].completions.bonus>0?0:1):2,img:activities[key].assigned===true?(activities[key].completions.personal+activities[key].completions.bonus>0?"/icons/check-circle-fancy.svg":"/icons/check-circle-broken.svg"):"/icons/slash-circle-01.svg"}


                }
            )
            index++;

        }
        
        setActOptions(temp);
    }
    
}, [activities]);

useEffect(() => {
     setSelectedValue(defaultAssignment!==undefined&&defaultAssignment!==null?defaultAssignment.Id:null)
}, [defaultAssignment]);

useEffect(() => {
}, [actSettings]);

useEffect(() => {
    if(optionChange!==null)
    {
        changeOption(optionChange[0],optionChange[1],optionChange[2])
        setOptionChange(null);
    }
}, [optionChange]);

useEffect(() => {
    if(selectedValue!==null&&selectedValue!==undefined&&activities!==null&&activities!==undefined){
        

        if(activities[selectedValue].duration_increment!==-1){
            var min = activities[selectedValue].duration/60;
                 var max = min*3;
                var durs: any[] =[];
                durs.push(min);
                var i =min;
                
                while(i< max)
                {
                    i+=1;
                    if(i>max){break;}
                    durs.push( Math.floor(i))
                    
                }
                var temp: any[] =[];
                
                durs.forEach((e,index)=>{
                    temp.push(
                        {
                            name: e+" minutes",
                            id: index,
                            value: e,
                            
        
                        }
                )
                })
                setDurations(temp)
        }
        else{
            setDurations(null);
        }
    }  
}, [selectedValue]);

useEffect(()=>{},[dur])

useEffect(()=>{
    let prevActivity= selectedValue
    
    if(originalAssignment!==null&&activities[originalAssignment.Id].hasOptions&&prevActivity===undefined){
        let settings=[]

        activities[originalAssignment.Id].OptionNames.forEach(val=>{
            settings.push({name: val, value:(originalAssignment.Settings!==undefined&&originalAssignment.Settings[val]!==undefined?originalAssignment.Settings[val]:false), onchange: setOptionChange })
        })
      
        setActSettings(settings)
    }
    
},[originalAssignment])


useEffect(() => {
   
     let  hasDuplicate=false
     if(selectedValue!==null)
     {
        currentAssignments.forEach((act,i)=>{
            if(i!==index)
            {
                
                if(act!==null&&act.Id===selectedValue)
                {
                    
                    if(activityState.length===1)
                    {
                        hasDuplicate=true;
                        if(activityState[0]===saveState.assigned)
                        {
                            setActivityState([saveState.assigned,saveState.duplicate])
                        }
                        if(activityState[0]===saveState.unsaved)
                        {
                            setActivityState([saveState.unsaved,saveState.duplicate])
                        }
                    }
                }
            }
        })
        if(!hasDuplicate&&activityState.length>1)
        {
            setActivityState([activityState[0]]);
        }
     }
}, [currentAssignments, originalAssignment,refreshCheck]);



    const ContextTextColor=(raw)=>{
        switch(true)
          {
            case raw===0:
                return "text-gray-700"
            case raw<1000:
                return "text-yellow-700";
              case raw<2500:
                  return 'text-lime-800';
              case raw<4000:
                  return 'text-green-light-700';
              default:
                   return 'text-success-700';
         
          }
    
      }

      const ContextBadgeBackgroundColor=(raw)=>{
        switch(true)
          {
            case raw===0:
                return "bg-gray-50"
            case raw<1000:
              return "bg-yellow-50";
            case raw<2500:
                return 'bg-lime-50';
            case raw<4000:
                return 'bg-green-light-50';
            default:
                 return 'bg-success-50';
         
          }
    
      }

    const ContextBarColor=(raw)=>{
        switch(true)
          {
           
            case raw===0:
                return "bg-gray-400"
            case raw<1000:
                return "bg-yellow-300";
              case raw<2500:
                  return 'bg-lime-300';
              case raw<4000:
                  return 'bg-green-light-300';
              default:
                   return 'bg-success-300';
         
          }
    
      }

     

      const ContextLevel=(raw)=>{
        let level =""
        switch(true)
          {
            case raw===0:
                level="None";
                break;
            case raw<1000:
              level="Keep Going ";
              break;
            case raw<2500:
              level= 'Good';
              break;
            case raw<4000:
              level='Great'
              break;
            default:
              level="Excellent"
              break;
          }
          
        return level
      }

      const badgeParse=(rank)=>{
        if(rank===1)
        {return "Bronze"}
        if(rank===2)
        {return "Silver"}
        if(rank>=3)
        {return "Gold"}
    }
   
      const GetActivityBadge=(actType,rank)=>{
        if(rank>0){
            var rankString= badgeParse(rank);
        return "/icons/badges/"+actType+"Activity_"+rankString+".png";
        }
        else
        {
            
                return "/icons/badges/"+actType+"Activity_"+"None"+".png";

            
           

        }
    }

    const ResetActivity=()=>{
        setActivityState([saveState.assigned])
        setDurState([saveState.assigned])
        if(originalAssignment!==null){
        setSelectedValue(originalAssignment.Id);
        setActivityMethod(originalAssignment.Id,week,index)
        setActOptions(null)
        }
        else{
            setSelectedValue(null);
            setActivityMethod(null,week,index)
        }

        if(originalDuration!==null&&originalDuration!==0&&originalAssignment!==null&&activities[originalAssignment.Id].duration_increment!==-1){


        setDur(originalDuration/60);
        setDurationMethod(originalDuration,originalAssignment.Id,week,index)
        }

        if(originalAssignment!==null&&activities[originalAssignment.Id].hasOptions){
            let settings=[]
    
            activities[originalAssignment.Id].OptionNames.forEach(val=>{
                settings.push({name: val, value:(originalAssignment.Settings!==undefined&&originalAssignment.Settings[val]!==undefined?originalAssignment.Settings[val]:false), onchange: setOptionChange })
                setOptionMethod(val,(originalAssignment.Settings!==undefined&&originalAssignment.Settings[val]!==undefined?originalAssignment.Settings[val]:false),week,index)

            })
            setActSettings(settings)

        }
        else
        {
            setActSettings(null);
        }
        setSettingsState([saveState.assigned]);



    }

    const GetActivityToolTip=(actType)=>{
        var path= "/icons/badges/CORE_"+actType+"ActivityIcon_T.png"
        return(
        <div className=" flex-shrink-0 flex has-tooltip">
            <span className='tooltip rounded text-wrap shadow-lg p-1 ml-4  -mt-6 inline-flex '>{actType +" Activity"}</span>

            <img className="w-7 h-7 left-0 top-0 hs-tooltip" src={path} />
        </div>
        )
    }


    const compareSettings=(newSettings,oldSettings)=>{
        if(originalAssignment!==null&&activities[originalAssignment.Id].hasOptions){
       
          return (oldSettings===JSON.stringify(newSettings))
            
        }
        return false;
    }
    

    const changeOption=(name,value, options)=>{
        let settings=options
        var oldSettings="";
        if(originalAssignment!==null&& selectedValue==originalAssignment.Id){
            let old=[]

        activities[originalAssignment.Id].OptionNames.forEach(val=>{
            old.push({name: val, value:(originalAssignment.Settings!==undefined&&originalAssignment.Settings[val]!==undefined?originalAssignment.Settings[val]:false), onchange: setOptionChange })
        })
        oldSettings= JSON.stringify(old);
        }
        else
        {
            oldSettings="";
        }
        settings?.forEach(setting=>{
            if(setting.name===name)
            {
                setting.value=value;
            }
        })
        setSettingsState(compareSettings(settings,oldSettings)?[saveState.assigned]:[saveState.unsaved]);
        setActSettings(settings)
        
        setOptionMethod(name,value,week,index);

    }


    const GetBorderColor=(state)=>{
        if(state.includes(saveState.assigned))
        {
            return "border-gray-300";

        }
        if(state.includes(saveState.duplicate))
        {
            return "border-warning-300";

        } 
        if(state.includes(saveState.unsaved))
        {
                return "border-error-500";

        }
       
    }



      const Render=(thedefault)=>{
   
        if(selectedValue===undefined){return;}
        if(isRoutineActivity)
        {
            if(state===ViewState.Active)
            {
                return (
                    <div className="w-full h-full p-4   flex-col justify-start items-start gap-4 inline-flex">
        <div className="justify-start items-center inline-flex w-full gap-x-2 ">
            <div className="text-gray-900 text-sm font-semibold font-['Inter'] text-nowrap	 leading-tight">{isCalibrationActivity?"Calibration Activity":"Movement Activity "+(index/2)}</div>
            {
                (activityState.includes(saveState.assigned)&&durState.includes(saveState.assigned)&&settingState.includes(saveState.assigned))&&<React.Fragment>
                    <div className="h-[22px] mix-blend-multiply justify-start items-start inline-flex">
<div className="px-3.5 py-0.5 bg-brand-50 rounded-2xl justify-center items-center gap-1 flex">
<img className="w-3 h-3 relative" src='/icons/check-square.svg' />
<div className="text-center text-brand-700 text-xs font-medium font-['Inter'] leading-[18px]">Assigned</div>
</div>
</div>
                </React.Fragment>
            }

{
                (activityState.includes(saveState.unsaved)||durState.includes(saveState.unsaved)||settingState.includes(saveState.unsaved))&&<React.Fragment>
                    <div className="h-[22px] mix-blend-multiply justify-start items-start inline-flex">
<div className="px-3.5 py-0.5 bg-warning-50 rounded-2xl justify-center items-center gap-1 flex">
<img className="w-3 h-3 relative" src='/icons/edit-02.svg' />
<div className="text-center text-warning-700 text-xs font-medium font-['Inter'] leading-[18px]">Unsaved</div>
</div>
</div>
                </React.Fragment>
            }

{
                (activityState.includes(saveState.duplicate)||durState.includes(saveState.duplicate))&&<React.Fragment>
                    <div className="h-[22px] mix-blend-multiply justify-start items-start inline-flex">
<div className="px-3.5 py-0.5 bg-error-50 rounded-2xl justify-center items-center gap-1 flex">
<img className="w-3 h-3 relative" src='/icons/copy-03.svg' />
<div className="text-center text-error-700 text-xs font-medium font-['Inter'] leading-[18px]">Duplicate</div>
</div>
</div>
                </React.Fragment>
            }
            {(activityState.includes(saveState.unsaved)||(durState.includes(saveState.unsaved))||(settingState.includes(saveState.unsaved)))?<React.Fragment>
                <div className='w-full '/>
            <button onClick={()=>{ResetActivity()}} className=" h-7 px-0.5  hover:bg-gray-300 bg-white  rounded-lg shadow border border-gray-300 justify-center items-center flex relative   ">
            <img className="w-8 h-4 " src='/icons/flip-backward.svg' />
            </button></React.Fragment>:
            <React.Fragment>
               <div className='w-full '/>
            <div className=" h-7  justify-center items-center flex relative   ">
            </div> 
            </React.Fragment>}
        </div>
        
     
        <div className="self-stretch h-9 justify-start items-center gap-2.5 inline-flex">
        {GetActivityToolTip( activities[selectedValue].type)}

            <div className="h-9 flex-col justify-start items-start inline-flex">
                <div className="  justify-start items-start gap-1 inline-flex">
                    <div className=" justify-center items-center flex">
                        {actOptionsFiltered!==null&&selectedValue!==null?<CustomDropDown borderColor={GetBorderColor(activityState)}  nameLimit={0} onchange={ChangeActivity} badgeText={["text-brand-700","text-gray-700","text-warning-700"]} badgeColors={["bg-brand-50","bg-gray-50","bg-warning-50"]} options={actOptionsFiltered  } defaultValue={selectedValue}  right={false} badgePercent={false} />:<span></span>}
                    </div>
                </div>
            </div>
            { actSettings!==null && 
         <OptionDropDown options={actSettings} borderState={GetBorderColor(settingState)}/>
           }
            <div className='w-full' >
           <FilterDropDown options={[{name:"Exclude Completed Activities", value:doneFilter, onchange: setDoneFilter }]}/>
           </div>
            <div className="grow shrink  self-stretch" />
            <div className="h-9 flex-col justify-start items-start inline-flex">
                
                    


                {durations!==null&&actOptionsFiltered!==null&&actOptionsFiltered.length>0?<CustomDropDown key={dur} options={durations  } borderColor={GetBorderColor(durState)}  defaultValue={dur} fullwidth={true} onchange={ChangeActivityDuration}/>:<span></span>}
            </div>

        </div>
        {activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?
        <div className="self-stretch justify-start items-center gap-2 inline-flex">

        <img className="w-5 h-5 relative" src="/icons/score.svg" />
            <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight text-nowrap	">Best Score</div>
            <div className="flex w-full h-2 relative rounded-lg">
                <div className="w-[100%] h-2 left-0 top-0 absolute bg-gray-200 rounded-full" />
                <div className={"w-[50%] h-2 left-0 top-0 absolute rounded-full "+ContextBarColor(activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].best_score.personal:0)} style={{width:(
                    Math.round((activities[selectedValue].weekly_stats[week].best_score.personal/5000)*100)<100?
                    Math.round((activities[selectedValue].weekly_stats[week].best_score.personal/5000)*100):100
                    )+'%'}} />
            </div>

            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].best_score.personal:0}</div>
            <div className="mix-blend-multiply justify-start items-start flex">
                <div className={"px-2 py-0.5  rounded-2xl justify-center items-center flex "+ContextBadgeBackgroundColor(activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].best_score.personal:0)}>
                    <div className={"text-center text-nowrap text-xs font-medium font-['Inter'] leading-[18px] "+ContextTextColor(activities[selectedValue].weekly_stats[week]!==null?activities[selectedValue].weekly_stats[week].best_score.personal:0)}>{ContextLevel(activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].best_score.personal:0)}</div>
                </div>
            </div>
        </div>:<div className="self-stretch justify-start items-center gap-2 inline-flex">
        <img className="w-5 h-5 relative" src="/icons/score.svg" />
            <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight text-nowrap	">Best Score</div>
            <div className="flex w-full h-2 relative rounded-lg">
                <div className="w-[100%] h-2 left-0 top-0 absolute bg-gray-200 rounded-full" />
                <div className={"w-[50%] h-2 left-0 top-0 absolute rounded-full "+ContextBarColor(0)} style={{width:(0
                    )+'%'}} />
            </div>
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{0}</div>
            <div className="mix-blend-multiply justify-start items-start flex">
                <div className={"px-2 py-0.5  rounded-2xl justify-center items-center flex "+ContextBadgeBackgroundColor(0)}>
                    <div className={"text-center text-xs font-medium font-['Inter'] leading-[18px] "+ContextTextColor(0)}>{ContextLevel(0)}</div>
                </div>
            </div>
        </div>}
        {activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?<div className="self-stretch items-center gap-2 leading-tight relative inline-flex">
            <div className=" h-5 gap-2  justify-start items-start flex">
            <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/greyCheck.svg" />
                <div className="text-slate-700  text-sm font-medium font-['Inter']  text-nowrap	">Completions</div>
            </div>
            <div className='self-stretch justify-end absolute items-center gap-2 inline-flex right-0'>
            <img className="w-7 h-7" src={GetActivityBadge(activities[selectedValue].type,activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].completions.personal+activities[selectedValue].weekly_stats[week].completions.bonus:0)}/>
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].completions.personal+activities[selectedValue].weekly_stats[week].completions.bonus:0}</div>
            </div>
        </div>:<div className="self-stretch items-center gap-2 leading-tight relative inline-flex">
            <div className=" h-5 gap-2  justify-start items-start flex">
            <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/greyCheck.svg" />
                <div className="text-slate-700  text-sm font-medium font-['Inter']  text-nowrap	">Completions</div>
            </div>
            <div className='self-stretch justify-end absolute items-center gap-2 inline-flex right-0'>
            <img className="w-7 h-7" src={GetActivityBadge(activities[selectedValue].type,0)}/>
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{0}</div>
            </div>
        </div>}
    </div> 
                )
            }
            else
            {
                return (
                  activities&&completedRoutineData&&  <div className="w-full h-full p-4   flex-col justify-start items-start gap-4 inline-flex">


        <div className="justify-start items-start gap-[5px] inline-flex">
            <div className="text-gray-900 text-sm font-semibold font-['Inter'] leading-tight">{isCalibrationActivity?"Calibration Activity":"Movement Activity "+(index/2)}</div>
        </div>
        <div className="self-stretch h-9 justify-start items-center gap-2.5 inline-flex">
        {completedRoutineData!==null &&completedRoutineData[week]!==undefined&&completedRoutineData[week].scores[index]!==undefined&&activities!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]]!==undefined&& GetActivityToolTip(activities[Object.keys( completedRoutineData[week].scores[index])[0]].type)}

            <div className="h-9 flex-col justify-start items-start inline-flex">
                <div className="px-3 py-2 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-1 inline-flex">
                    <div className="px-0.5 justify-center items-center flex">
                        <div className="text-gray-700 text-sm font-semibold font-['Inter'] leading-tight">{activities[Object.keys( completedRoutineData[week].scores[index])[0]].name}</div>
                    </div>
                </div>
            </div>
           
            <div className="grow shrink basis-0 self-stretch" />
           
        </div>
        <div className="self-stretch justify-start items-center gap-2 inline-flex">
        <img className="w-5 h-5 relative" src="/icons/score.svg" />
            <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight text-nowrap	">Best Score</div>
            <div className="flex w-full h-2 relative rounded-lg">
                <div className="w-[100%] h-2 left-0 top-0 absolute bg-gray-200 rounded-full" />
                
               { completedRoutineData!==null &&completedRoutineData[week]!==undefined&&activities!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]]!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week]!==undefined? <div className={"w-[50%] h-2 left-0 top-0 absolute rounded-full "+ContextBarColor(activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].best_score.personal)} style={{width:(
                    Math.round((activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].best_score.personal/5000)*100)<100?
                    Math.round((activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].best_score.personal/5000)*100):100
                    )+'%'}} />:<div className={"h-2 left-0 top-0 absolute rounded-full "+ContextBarColor(0)} style={{width:'0%'}} />
                }
            </div>
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{completedRoutineData!==null &&completedRoutineData[week]!==undefined&&activities!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]]!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week]!==undefined?activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].best_score.personal:0}</div>
            <div className="mix-blend-multiply justify-start items-start flex">
                <div className={"px-2 py-0.5  rounded-2xl justify-center items-center flex "+ContextBadgeBackgroundColor(completedRoutineData!==null &&completedRoutineData[week]!==undefined&&activities!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]]!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week]!==undefined?activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].best_score.personal:0)}>
                    <div className={"text-center text-xs font-medium font-['Inter'] leading-[18px] "+ContextTextColor(completedRoutineData!==null &&completedRoutineData[week]!==undefined&&activities!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]]!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week]!==undefined?activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].best_score.personal:0)}>{ContextLevel(completedRoutineData!==null &&completedRoutineData[week]!==undefined&&activities!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]]!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week]!==undefined?activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].best_score.personal:0)}</div>
                </div>
            </div>
        </div>
        <div className="self-stretch items-center gap-2 leading-tight relative inline-flex">
            <div className=" h-5 gap-2  justify-start items-start flex">
            <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/greyCheck.svg" />
                <div className="text-slate-700  text-sm font-medium font-['Inter']  text-nowrap	">Completions</div>
            </div>
            <div className='self-stretch justify-end absolute items-center gap-2 inline-flex right-0'>
            <img className="w-7 h-7" src={GetActivityBadge("Movement",completedRoutineData!==null &&completedRoutineData[week]!==undefined&&activities!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]]!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week]!==undefined?activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].completions.personal+activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].completions.bonus:0)}/>
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{completedRoutineData!==null &&completedRoutineData[week]!==undefined&&activities!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]]!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats!==undefined&&activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week]!==undefined?activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].completions.personal+activities[Object.keys( completedRoutineData[week].scores[index])[0]].weekly_stats[week].completions.bonus:0}</div>
            </div>
        </div>
    </div> 
                )
            }
        }
        else
        {
            if(state===ViewState.Active){
                if(selectedValue!==null){
                return (
                    <div className="w-full h-full p-4   flex-col justify-start items-start gap-4 inline-flex">
        <div className="justify-start items-center gap-[5px] inline-flex w-full">
            <div className="text-gray-900 text-sm font-semibold font-['Inter'] leading-tight text-nowrap">{isCalibrationActivity?"Calibration Activity":"Bonus Activity "+(index+1)}</div>
            {
                (activityState.includes(saveState.assigned)&&durState.includes(saveState.assigned)&&settingState.includes(saveState.assigned))&&<React.Fragment>
                    <div className="h-[22px] mix-blend-multiply justify-start items-start inline-flex">
<div className="px-3.5 py-0.5 bg-brand-50 rounded-2xl justify-center items-center gap-1 flex">
<img className="w-3 h-3 relative" src='/icons/check-square.svg' />
<div className="text-center text-brand-700 text-xs font-medium font-['Inter'] leading-[18px]">Assigned</div>
</div>
</div>
                </React.Fragment>
            }

{
                (activityState.includes(saveState.unsaved)||durState.includes(saveState.unsaved)||settingState.includes(saveState.unsaved))&&<React.Fragment>
                    <div className="h-[22px] mix-blend-multiply justify-start items-start inline-flex">
<div className="px-3.5 py-0.5 bg-warning-50 rounded-2xl justify-center items-center gap-1 flex">
<img className="w-3 h-3 relative" src='/icons/edit-02.svg' />
<div className="text-center text-warning-700 text-xs font-medium font-['Inter'] leading-[18px]">Unsaved</div>
</div>
</div>
                </React.Fragment>
            }

{
                (activityState.includes(saveState.duplicate)||durState.includes(saveState.duplicate))&&<React.Fragment>
                    <div className="h-[22px] mix-blend-multiply justify-start items-start inline-flex">
<div className="px-3.5 py-0.5 bg-error-50 rounded-2xl justify-center items-center gap-1 flex">
<img className="w-3 h-3 relative" src='/icons/copy-03.svg' />
<div className="text-center text-error-700 text-xs font-medium font-['Inter'] leading-[18px]">Duplicate</div>
</div>
</div>
                </React.Fragment>
            }
            {(activityState.includes(saveState.unsaved)||(durState.includes(saveState.unsaved))||(settingState.includes(saveState.unsaved)))?<React.Fragment>
                <div className='w-full '/>
            <button onClick={()=>{ResetActivity()}} className=" h-7  px-0.5   hover:bg-gray-300 bg-white  rounded-lg shadow border border-gray-300 justify-center items-center flex relative   ">
            <img className="w-8 h-4 " src='/icons/flip-backward.svg' />
            </button></React.Fragment>:
            <React.Fragment>
               <div className='w-full '/>
            <div className=" h-7  justify-center items-center flex relative   ">
            </div> 
            </React.Fragment>}
        </div>
        <div className="self-stretch h-9 justify-start items-center gap-2.5 inline-flex">
        {activities[selectedValue]!==undefined&&GetActivityToolTip(activities[selectedValue].type)}

            <div className="h-9 flex-col justify-start items-start inline-flex">
                <div className="  justify-start items-start gap-1 inline-flex">
                    <div className=" justify-center items-center flex">
                        {actOptionsFiltered!==null&&selectedValue!==null?<CustomDropDown borderColor={GetBorderColor(activityState)}  nameLimit={0} onchange={ChangeActivity}  badgeText={["text-brand-700","text-gray-700","text-warning-700"]} badgeColors={["bg-brand-50","bg-gray-50","bg-warning-50"]} options={actOptionsFiltered  } defaultValue={selectedValue} right={false} badgePercent={false}/>:<span></span>}
                    </div>
                </div>
            </div>
            { actSettings!==null && 
         <OptionDropDown options={actSettings} borderState={GetBorderColor(settingState)}/>
           }
            <div className='w-full' >
           <FilterDropDown options={[{name:"Coaching Activities", value:coachingFilter, onchange: setcoachingFilter },{name:"Meditation Activities", value:meditationFilter, onchange: setMeditationFilter },{name:"Movement Activities", value:movementFilter, onchange: setMovementFilter },{name:"Exclude Completed Activities", value:doneFilter, onchange: setDoneFilter },]}/>
           </div>
            <div className="grow shrink  self-stretch" />
            <div className="h-9 flex-col justify-start items-start inline-flex">
                
                    


                {durations!==null&&actOptionsFiltered!==null&&actOptionsFiltered.length>0?<CustomDropDown key={dur}  borderColor={GetBorderColor(durState)} options={durations  } defaultValue={dur} fullwidth={true} onchange={ChangeActivityDuration}/>:<span></span>}
            </div>

        </div>
        {activities[selectedValue].type==="Movement"&&(activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?
        <div className="self-stretch justify-start items-center gap-2 inline-flex">

        <img className="w-5 h-5 relative" src="/icons/score.svg" />
            <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight text-nowrap	">Best Score</div>
            <div className="flex w-full h-2 relative rounded-lg">
                <div className="w-[100%] h-2 left-0 top-0 absolute bg-gray-200 rounded-full" />
                <div className={" h-2 left-0 top-0 absolute rounded-full "+ContextBarColor(activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].best_score.bonus:0)} style={{width:(
                    Math.round((activities[selectedValue].weekly_stats[week].best_score.bonus/5000)*100)<100?
                    Math.round((activities[selectedValue].weekly_stats[week].best_score.bonus/5000)*100):100
                    )+'%'}} />
            </div>

            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].best_score.bonus:0}</div>
            <div className="mix-blend-multiply justify-start items-start flex">
                <div className={"px-2 py-0.5  rounded-2xl justify-center items-center flex "+ContextBadgeBackgroundColor(activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].best_score.bonus:0)}>
                    <div className={"text-center text-xs text-nowrap font-medium font-['Inter'] leading-[18px] "+ContextTextColor(activities[selectedValue].weekly_stats[week]!==null?activities[selectedValue].weekly_stats[week].best_score.bonus:0)}>{ContextLevel(activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].best_score.bonus:0)}</div>
                </div>
            </div>
        </div>:<div className="self-stretch justify-start items-center gap-2 inline-flex">
        <img className="w-5 h-5 relative" src="/icons/score.svg" />
            <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight text-nowrap	">Best Score</div>
            <div className="flex w-full h-2 relative rounded-lg">
                <div className="w-[100%] h-2 left-0 top-0 absolute bg-gray-200 rounded-full" />
                <div className={"w-[50%] h-2 left-0 top-0 absolute rounded-full "+ContextBarColor(0)} style={{width:(0
                    )+'%'}} />
            </div>
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{0}</div>
            <div className="mix-blend-multiply justify-start items-start flex">
                <div className={"px-2 py-0.5  rounded-2xl justify-center items-center flex "+ContextBadgeBackgroundColor(0)}>
                    <div className={"text-center text-nowrap text-xs font-medium font-['Inter'] leading-[18px] "+ContextTextColor(0)}>{ContextLevel(0)}</div>
                </div>
            </div>
        </div>)}
        {activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?<div className="self-stretch items-center gap-2 leading-tight relative inline-flex">
            <div className=" h-5 gap-2  justify-start items-start flex">
            <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/greyCheck.svg" />
                <div className="text-slate-700  text-sm font-medium font-['Inter']  text-nowrap	">Completions</div>
            </div>
            <div className='self-stretch justify-end absolute items-center gap-2 inline-flex right-0'>
            <img className="w-7 h-7" src={GetActivityBadge(activities[selectedValue].type,activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].completions.personal+activities[selectedValue].weekly_stats[week].completions.bonus:0)}/>
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{activities!==null&&activities[selectedValue]!==undefined&&activities[selectedValue].weekly_stats!==undefined&&activities[selectedValue].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].completions.personal+activities[selectedValue].weekly_stats[week].completions.bonus:0}</div>
            </div>
        </div>:<div className="self-stretch items-center gap-2 leading-tight relative inline-flex">
            <div className=" h-5 gap-2  justify-start items-start flex">
            <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/greyCheck.svg" />
                <div className="text-slate-700  text-sm font-medium font-['Inter']  text-nowrap	">Completions</div>
            </div>
            <div className='self-stretch justify-end absolute items-center gap-2 inline-flex right-0'>
            {<img className="w-7 h-7" src={GetActivityBadge((activities[selectedValue]!==undefined?activities[selectedValue].type:"Coaching"),0)}/>}
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{0}</div>
            </div>
        </div>}
    </div> 
                )
            }
                else
                {
                    return (
        <div className="w-full h-full p-4   flex-col justify-start items-start gap-4 inline-flex">
            <div className="justify-start items-start gap-[5px] inline-flex">
                <div className="text-gray-900 text-sm font-semibold font-['Inter'] leading-tight">{isCalibrationActivity?"Calibration Activity":"Bonus Activity "+(index+1)}</div>
            </div>
            <div className="self-stretch h-9 justify-start items-center gap-2.5 inline-flex">    
                <div className="h-9 flex-col justify-start items-start inline-flex">
                    <div className="  justify-start items-start gap-1 inline-flex">
                        <div className=" justify-center items-center flex">
                            {actOptionsFiltered!==null?<CustomDropDown nameLimit={0} borderColor={GetBorderColor(activityState)} onchange={ChangeActivity}  badgeText={["text-brand-700","text-gray-700","text-warning-700"]} badgeColors={["bg-brand-50","bg-gray-50","bg-warning-50"]} options={actOptionsFiltered  } defaultValue={selectedValue} right={false} badgePercent={false}/>:<span></span>}
                        </div>
                    </div>
                </div>
                { actSettings!==null && 
         <OptionDropDown options={actSettings} borderState={GetBorderColor(settingState)}/>
           }
                <div className='w-full' > 
           <FilterDropDown options={[{name:"Coaching Activities", value:coachingFilter, onchange: setcoachingFilter },{name:"Meditation Activities", value:meditationFilter, onchange: setMeditationFilter },{name:"Movement Activities", value:movementFilter, onchange: setMovementFilter },{name:"Exclude Completed Activities", value:doneFilter, onchange: setDoneFilter },]}/>
           </div>
            </div>
           
        </div> 
                    )
                }
            }
            else if(state===ViewState.Pending)
            {
                if(selectedValue!==null){
                    return (
                        <div className="w-full h-full p-4   flex-col justify-start items-start gap-4 inline-flex">
            <div className="justify-start items-center w-full gap-[5px] inline-flex">
                <div className="text-gray-900 text-sm font-semibold font-['Inter'] leading-tight text-nowrap">{isCalibrationActivity?"Calibration Activity":"Bonus Activity "+(index+1)}</div>
                {
                (activityState.includes(saveState.assigned)&&durState.includes(saveState.assigned)&&settingState.includes(saveState.assigned))&&<React.Fragment>
                    <div className="h-[22px] mix-blend-multiply justify-start items-start inline-flex">
<div className="px-3.5 py-0.5 bg-brand-50 rounded-2xl justify-center items-center gap-1 flex">
<img className="w-3 h-3 relative" src='/icons/check-square.svg' />
<div className="text-center text-brand-700 text-xs font-medium font-['Inter'] leading-[18px]">Assigned</div>
</div>
</div>
                </React.Fragment>
            }

{
                (activityState.includes(saveState.unsaved)||durState.includes(saveState.unsaved)||settingState.includes(saveState.unsaved))&&<React.Fragment>
                    <div className="h-[22px] mix-blend-multiply justify-start items-start inline-flex">
<div className="px-3.5 py-0.5 bg-warning-50 rounded-2xl justify-center items-center gap-1 flex">
<img className="w-3 h-3 relative" src='/icons/edit-02.svg' />
<div className="text-center text-warning-700 text-xs font-medium font-['Inter'] leading-[18px]">Unsaved</div>
</div>
</div>
                </React.Fragment>
            }

{
                (activityState.includes(saveState.duplicate)||durState.includes(saveState.duplicate))&&<React.Fragment>
                    <div className="h-[22px] mix-blend-multiply justify-start items-start inline-flex">
<div className="px-3.5 py-0.5 bg-error-50 rounded-2xl justify-center items-center gap-1 flex">
<img className="w-3 h-3 relative" src='/icons/copy-03.svg' />
<div className="text-center text-error-700 text-xs font-medium font-['Inter'] leading-[18px]">Duplicate</div>
</div>
</div>
                </React.Fragment>
            }
                {(activityState.includes(saveState.unsaved)||(durState.includes(saveState.unsaved))||(settingState.includes(saveState.unsaved)))?<React.Fragment>
                <div className='w-full '/>
            <button onClick={()=>{ResetActivity()}} className=" h-7  px-0.5   hover:bg-gray-300 bg-white  rounded-lg shadow border border-gray-300 justify-center items-center flex relative   ">
            <img className="w-8 h-4 " src='/icons/flip-backward.svg' />
            </button></React.Fragment>:
            <React.Fragment>
               <div className='w-full '/>
            <div className=" h-7  justify-center items-center flex relative   ">
            </div> 
            </React.Fragment>}
            </div>
            <div className="self-stretch h-9 justify-start items-center gap-2.5 inline-flex">
            {activities[selectedValue]!==undefined&&GetActivityToolTip(activities[selectedValue].type)}
    
                <div className="h-9 flex-col justify-start items-start inline-flex">
                    <div className="  justify-start items-start gap-1 inline-flex">
                        <div className=" justify-center items-center flex">
                            {actOptionsFiltered!==null&&selectedValue!==null?<CustomDropDown borderColor={GetBorderColor(activityState)} nameLimit={0} onchange={ChangeActivity} badgeText={["text-brand-700","text-gray-700","text-warning-700"]} badgeColors={["bg-brand-50","bg-gray-50","bg-warning-50"]} options={actOptionsFiltered  } defaultValue={selectedValue} right={false} badgePercent={false}/>:<span></span>}
                        </div>
                    </div>
                </div>
                { actSettings!==null && 
         <OptionDropDown options={actSettings} borderState={GetBorderColor(settingState)}/>
           }
                <div className='w-full' >
                <FilterDropDown options={[{name:"Coaching Activities", value:coachingFilter, onchange: setcoachingFilter },{name:"Meditation Activities", value:meditationFilter, onchange: setMeditationFilter },{name:"Movement Activities", value:movementFilter, onchange: setMovementFilter },{name:"Exclude Completed Activities", value:doneFilter, onchange: setDoneFilter },]}/>
               </div>
                <div className="grow shrink  self-stretch" />
                <div className="h-9 flex-col justify-start items-start inline-flex">
                    {durations!==null&&actOptionsFiltered!==null&&actOptionsFiltered.length>0?<CustomDropDown key={dur} options={durations  } borderColor={GetBorderColor(durState)}  defaultValue={dur} fullwidth={true} onchange={ChangeActivityDuration}/>:<span></span>}
                </div>
    
            </div>
            
            
        </div> 
                    )
                }
                else
                    {
                        return (
            <div className="w-full h-full p-4   flex-col justify-start items-start gap-4 inline-flex">
                <div className="justify-start items-start gap-[5px] inline-flex">
                    <div className="text-gray-900 text-sm font-semibold font-['Inter'] leading-tight">{isCalibrationActivity?"Calibration Activity":"Bonus Activity "+(index+1)}</div>
                </div>
                <div className="self-stretch h-9 justify-start items-center gap-2.5 inline-flex">    
                    <div className="h-9 flex-col justify-start items-start inline-flex">
                        <div className="  justify-start items-start gap-1 inline-flex">
                            <div className=" justify-center items-center flex">
                                {actOptionsFiltered!==null?<CustomDropDown nameLimit={0} onchange={ChangeActivity} borderColor={GetBorderColor(activityState)}  badgeText={["text-brand-700","text-gray-700","text-warning-700"]} badgeColors={["bg-brand-50","bg-gray-50","bg-warning-50"]} options={actOptionsFiltered  } defaultValue={selectedValue} right={false} badgePercent={false}/>:<span></span>}
                            </div>
                        </div>
                    </div>
                    { actSettings!==null && 
         <OptionDropDown options={actSettings} borderState={GetBorderColor(settingState)}/>
           }
                    <div className='w-full' >
           <FilterDropDown options={[{name:"Coaching Activities", value:coachingFilter, onchange: setcoachingFilter },{name:"Meditation Activities", value:meditationFilter, onchange: setMeditationFilter },{name:"Movement Activities", value:movementFilter, onchange: setMovementFilter },{name:"Exclude Completed Activities", value:doneFilter, onchange: setDoneFilter },]}/>
           </div>
                </div>
                
            </div> 
                        )
                } 
            }
            else{
               

                if(activities!==null&&defaultAssignment===null){
                    return (
                        <div className="w-full h-full p-4   flex-col justify-start items-start gap-4 inline-flex">
            <div className="justify-start items-start gap-[5px] inline-flex">
                <div className="text-gray-900 text-sm font-semibold font-['Inter'] leading-tight">{"Bonus Activity "+(index+1)}</div>
            </div>
            <div className="self-stretch h-9 justify-start items-center gap-2.5 inline-flex">
                
                <div className="h-9 flex-col justify-start items-start inline-flex">
                    <div className="px-3 py-2 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-1 inline-flex">
                        <div className="px-0.5 justify-center items-center flex">
                            <div className="text-gray-700 text-sm font-semibold font-['Inter'] leading-tight">{"No Activity Assigned"}</div>
                        </div>
                    </div>
                </div>
                
            </div>
            
            
        </div> 
                    )
                }
                return (
                    activities!==null&&defaultAssignment!==null&&activities!==undefined&&defaultAssignment!==undefined&&<div className="w-full h-full p-4   flex-col justify-start items-start gap-4 inline-flex">
        <div className="justify-start items-start gap-[5px] inline-flex">
            <div className="text-gray-900 text-sm font-semibold font-['Inter'] leading-tight">{"Bonus Activity "+(index+1)}</div>
        </div>
        <div className="self-stretch h-9 justify-start items-center gap-2.5 inline-flex">
            {GetActivityToolTip(activities[defaultAssignment.Id].type)}
          
            <div className="h-9 flex-col justify-start items-start inline-flex">
                <div className="px-3 py-2 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-1 inline-flex">
                    <div className="px-0.5 justify-center items-center flex">
                        <div className="text-gray-700 text-sm font-semibold font-['Inter'] leading-tight">{activities[defaultAssignment.Id].name}</div>
                    </div>
                </div>
            </div>
           
        </div>
       {activities[defaultAssignment.Id].type==="Movement"&& (activities!==null&&activities!==undefined&&activities[defaultAssignment.Id]!==undefined&&activities[defaultAssignment.Id].weekly_stats!==undefined&&activities[defaultAssignment.Id].weekly_stats[week]!==undefined?
       <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <img className="w-5 h-5 relative" src="/icons/score.svg" />
            <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight text-nowrap	">Best Score</div>
            <div className="flex w-full h-2 relative rounded-lg">
                <div className="w-[100%] h-2 left-0 top-0 absolute bg-gray-200 rounded-full" />
            
                <div className={" h-2 left-0 top-0 absolute rounded-full "+ContextBarColor(activities!==null&&activities!==undefined&&activities[defaultAssignment.Id]!==undefined&&activities[defaultAssignment.Id].weekly_stats!==undefined&&activities[defaultAssignment.Id].weekly_stats[week]!==undefined?activities[defaultAssignment.Id].weekly_stats[week].best_score.bonus:0)} style={{width:(
                    Math.round((activities[defaultAssignment.Id].weekly_stats[week].best_score.bonus/5000)*100)<100?
                    Math.round((activities[defaultAssignment.Id].weekly_stats[week].best_score.bonus/5000)*100):100)
                    +'%'}} />
            </div>
            
            
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{activities[defaultAssignment.Id]!==undefined?activities[defaultAssignment.Id].weekly_stats[week].best_score.bonus:0}</div>
            <div className="mix-blend-multiply justify-start items-start flex">
                <div className={"px-2 py-0.5  rounded-2xl justify-center items-center flex "+ContextBadgeBackgroundColor(activities!==null&&activities!==undefined&&activities[defaultAssignment.Id]!==undefined&&activities[defaultAssignment.Id].weekly_stats!==undefined&&activities[defaultAssignment.Id].weekly_stats[week]!==undefined?activities[defaultAssignment.Id].weekly_stats[week].best_score.bonus:0)}>
                    <div className={"text-center text-nowrap text-xs font-medium font-['Inter'] leading-[18px] "+ContextTextColor(activities[defaultAssignment.Id].weekly_stats[week].best_score.bonus)}>{ContextLevel(activities!==null&&activities!==undefined&&activities[defaultAssignment.Id]!==undefined&&activities[defaultAssignment.Id].weekly_stats!==undefined&&activities[defaultAssignment.Id].weekly_stats[week]!==undefined?activities[defaultAssignment.Id].weekly_stats[week].best_score.bonus:0)}</div>
                </div>
            </div>
        </div>:<div className="self-stretch justify-start items-center gap-2 inline-flex">
            <img className="w-5 h-5 relative" src="/icons/score.svg" />
            <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight text-nowrap	">Best Score</div>
            <div className="flex w-full h-2 relative rounded-lg">
                <div className="w-[100%] h-2 left-0 top-0 absolute bg-gray-200 rounded-full" />
            
                <div className={" h-2 left-0 top-0 absolute rounded-full "+ContextBarColor(0)} style={{width:'0%'}} />
            </div>
            
            
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{0}</div>
            <div className="mix-blend-multiply justify-start items-start flex">
                <div className={"px-2 py-0.5  rounded-2xl justify-center items-center flex "+ContextBadgeBackgroundColor(0)}>
                    <div className={"text-center text-nowrap text-xs font-medium font-['Inter'] leading-[18px] "+ContextTextColor(0)}>{ContextLevel(0)}</div>
                </div>
            </div>
        </div>)}
        <div className="self-stretch items-center  gap-2 relative inline-flex">
            <div className=" h-5 gap-2 leading-tight justify-start items-start flex">
            <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/greyCheck.svg" />
                <div className="text-slate-700 text-sm font-medium font-['Inter'] text-nowrap	">Completions</div>
            </div>
            <div className='self-stretch justify-end absolute items-center gap-2 inline-flex right-0'>
                {}
            <img className="w-7 h-7" src={GetActivityBadge(activities[defaultAssignment.Id].type,activities!==null&&activities!==undefined&&activities[defaultAssignment.Id]!==undefined&&activities[defaultAssignment.Id].weekly_stats!==undefined&&activities[defaultAssignment.Id].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].completions.personal+activities[selectedValue].weekly_stats[week].completions.bonus:0)}/>
            <div className="text-right text-slate-700 text-sm font-medium font-['Inter'] leading-tight">{activities!==null&&activities!==undefined&&activities[defaultAssignment.Id]!==undefined&&activities[defaultAssignment.Id].weekly_stats!==undefined&&activities[defaultAssignment.Id].weekly_stats[week]!==undefined?activities[selectedValue].weekly_stats[week].completions.personal+activities[selectedValue].weekly_stats[week].completions.bonus:0}</div>
            </div>
        </div>
    </div> 
                )
            }
        }
      }
 
 return (
    Render(selectedValue)
 )
}

export default ActivityAssignmentPanel